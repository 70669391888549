.container {
  display: grid;
  grid-template-columns: 306px auto minmax(500px, 25%);
  grid-template-rows: 1fr;
  height: 100%;
  background-color: #f4f7fa;
  grid-gap: 24px;
  padding: 24px;
  padding-top: 12px !important;
}

.main {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.main--left--hide {
  grid-column-start: 1;
}

.main--right--hide {
  grid-column-end: 4;
}

.left {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.right {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  position: relative;
}
