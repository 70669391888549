.form {
  display: flex;
  flex-direction: column;
  max-height: 89vh;
  padding: 24px;
  padding-right: 12px;
}

.header {
  min-height: 60px;
}

.main {
  flex: 1 1 0;
  padding-right: 12px;
}

.footer {
  display: flex;
  justify-content: space-around;
  margin: 12px 0;
  margin-right: 24px;
}

.scroll {
}
